import React from 'react';
import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import AppRoute from 'utils/AppRoute';

const { Text } = Typography;
const columns: ColumnsType<any> = [
  {
    title: 'ល.រ',
    dataIndex: 'id',
    align: 'center',
    width: '36px',
    render: (e, record, index) => <Text>{index + 1}</Text>,
  },
  {
    title: 'ក្រុមការងារ / មន្ទីរ​ / ក្រុងស្រុក',
    dataIndex: 'id',
    align: 'center',
    render: (e, record) => (
      <Text style={{ textAlign: 'left', display: 'block' }}>
        {record?.fn || ''} {record?.ln || ''}
        {/* <Link to={`${AppRoute.document}?fq=user:${record._id}`}>
          {record?.fn || ''} {record?.ln || ''}
        </Link> */}
      </Text>
    ),
  },
  {
    title: 'ចំនួនករណី',
    dataIndex: 'id',
    align: 'center',
    render: (e, record) => <Text>{record?.totalDoc || 0}</Text>,
  },
  {
    title: 'លទ្ធផល',
    dataIndex: 'id',
    align: 'center',
    children: [
      {
        title: 'ចំនួនសំណុំរឿង',
        dataIndex: 'id',
        align: 'center',
        render: (e, record) => (
          <Text>
            {record?.totalDocSet || 0}
            {/* <Link
              to={`${AppRoute.document}?fq=user:${record._id},docStatus:inprogress`}
            >
            </Link> */}
          </Text>
        ),
      },
      {
        title: 'ដោះស្រាយបញ្ចប់',
        dataIndex: 'id',
        align: 'center',
        render: (e, record) => (
          <Text>
            {record?.totalFinish || 0}
            {/* <Link
              to={`${AppRoute.document}?fq=user:${record._id},docStatus:finish`}
            >
            </Link> */}
          </Text>
        ),
      },
      {
        title: 'កំពុងដំណើរការ',
        dataIndex: 'id',
        align: 'center',
        render: (e, record) => (
          <Text>
            {record?.totalInProgress || 0}
            {/* <Link to={`${AppRoute.document}?fq=user:${record._id}`}>
            </Link> */}
          </Text>
        ),
      },
    ],
    // render: (e, record) => (
    //   <Text>
    //     <Link
    //       to={`${AppRoute.document}?fq=user:${record._id},docStatus:inprogress`}
    //     >
    //       {record?.totalInProgress || 0}
    //     </Link>
    //   </Text>
    // ),
  },
];

function DocByAssignToReport({ data }) {
  return (
    <Table
      size="small"
      rowKey="id"
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      summary={pageData => {
        let summary = {
          inProgress: 0,
          finish: 0,
          totalDoc: 0,
          totalDocSign: 0,
          totalDocUnsign: 0,
        };

        pageData.forEach(
          ({
            totalInProgress,
            totalFinish,
            totalDocSign,
            totalDocUnsign,
            totalDoc,
          }) => {
            summary.totalDoc += totalDoc || 0;
            summary.inProgress += totalInProgress || 0;
            summary.finish += totalFinish || 0;
            summary.totalDocSign += totalDocSign || 0;
            summary.totalDocUnsign += totalDocUnsign || 0;
          },
        );

        return (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2} align="center" index={1}>
                <Text strong>សរុប</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                align="center"
                className="bg-light-yellow"
              >
                <Text>
                  {summary.totalDoc}
                  {/* <Link
                    to={`${AppRoute.document}?fq=docStatus:inprogress,user:all`}
                  ></Link> */}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                align="center"
                className="bg-light-yellow"
              >
                <Text>
                  {summary.inProgress + summary.finish}
                  {/* <Link
                    to={`${AppRoute.document}?fq=docStatus:inprogress,user:all`}
                  >
                  </Link> */}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={3}
                align="center"
                className="bg-light-yellow"
              >
                <Text>
                  {summary.finish}
                  {/* <Link
                    to={`${AppRoute.document}?fq=docStatus:finish,user:all`}
                  >
                  </Link> */}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={4}
                align="center"
                className="bg-light-red"
              >
                <Text>
                  {summary.inProgress}
                  {/* <Link to={`${AppRoute.document}?fq=user:all`}>
                  </Link> */}
                </Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
    />
  );
}

export default DocByAssignToReport;
